<template>
  <SimplePageNorm title="发货管理">
    <StatusTabs :status-list="statusList" v-model="status" @change="statusChange"></StatusTabs>
    <OrderTable style="width:100%;height:calc(100% - 38px);" v-loading="loading" :data-list="dataList" @dataUpdate="loadData" show-type="normal"></OrderTable>
  </SimplePageNorm>
</template>

<script>
import SimplePageNorm from '@/components/base/SimplePageNorm'
import OrderTable from './detail/orderTable'
import StatusTabs from './detail/statusTabs'

import {
  orderRequest
} from '@/api'

export default {
  components: {
    SimplePageNorm,
    OrderTable,
    StatusTabs
  },
  created() {
    this.loadData()
  },
  data () {
    return {
      loading: false,
      status: 'wait_ship',
      statusList: [{
        key: 'wait_ship',
        label: '待发货',
        parm: {
          haveWaitShip: 1
        }
      },
      {
        key: 'sending',
        label: '已发货',
        parm: {
          haveSending: 1
        }
      },
      {
        key: 'get',
        label: '已收货',
        parm: {
          haveInventory: 1
        }
      }],
      dataList: []
    }
  },
  methods: {
    async loadData () {
      this.loading = true
      let parm = {
        typeList: 'normal',
        statusList:'wait,pass,down'
      }
      let status = this.statusList.find(v => v.key == this.status)
      parm = Object.assign(parm, (status ? status.parm : {}))
      this.dataList = await orderRequest.get(parm)
      let statusMap = {
        edit: '调整中',
        wait: '待审核',
        pass: '审核通过',
        down: '审核不通过'
      }
      this.dataList.forEach(v => {
        v.statusText = statusMap[v.status]
        v.makeType = v.type.includes('back') ? '退货' : (v.makeMainbodyId === v.buyMainbodyId ? '自主下单' : '代客下单')
      })
      this.loading = false
    },
    statusChange (value) {
      this.status = value
      this.loadData()
    }
  }
}
</script>

<style scoped lang="less"></style>